import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useAccountActivity from '@/hooks/activity/useAccountActivity';
import { ActivityItemModel } from '@/models/Activity';
import { dateTimeFormatter } from '@/utility/date';

import ScrollToTopButton from '../components/utility/buttons/ScrollToTopButton';
import Loading from '../components/utility/Loading';
import UserHeader from '../components/utility/navigation/UserHeader';

const ActivityPage = () => {
  const { t } = useTranslation();
  const [activities, setActivities] = useState<ActivityItemModel[][]>([]);

  const { accountActivity, accountActivityIsLoading } = useAccountActivity();

  const sortActivities = useCallback(() => {
    if (!accountActivity) return;
    if (accountActivity) {
      const items: ActivityItemModel[][] = [];
      accountActivity.forEach((item) => {
        if (
          items.length > 0 &&
          dateTimeFormatter(item.updatedAt, 'date') === dateTimeFormatter(items[items.length - 1][0]?.updatedAt, 'date')
        ) {
          items[items.length - 1].push(item);
        } else {
          items.push([item]);
        }
      });
      setActivities(items);
    }
  }, [accountActivity]);

  useEffect(() => {
    sortActivities();
  }, [accountActivity, sortActivities]);

  return (
    <div data-testid="activity-page" className="page-content">
      <UserHeader title="PAGE-TITLES.ACTIVITY" />
      <ScrollToTopButton />
      {!accountActivityIsLoading && accountActivity && accountActivity.length > 0 && (
        <div className="w50p-lg-up w100p-lg-down mt20">
          {activities &&
            activities.length > 0 &&
            activities.map((activityDay: ActivityItemModel[], index: number) => (
              <div key={index}>
                <p className="text-faded mb20">{dateTimeFormatter(activityDay[0].updatedAt, 'dateTimeAgo')}</p>
                <div
                  className="card mb20 list-item-parent"
                  style={
                    {
                      '--animation-number': `${index}`,
                    } as React.CSSProperties
                  }
                >
                  {activityDay.map((activity: ActivityItemModel, index: number) => (
                    <div
                      key={index}
                      className={
                        activityDay.length > 1 && index < activityDay.length - 1 ? 'mb20 pb20 border-bottom' : ''
                      }
                    >
                      <div key={index} className="d-flex mb8">
                        <img
                          src={`/images/logos/${activity.platform}-logo.svg`}
                          alt={`${activity.platform}`}
                          className="activity-logo"
                        />
                        <p className={`small text-brand ${activity.platform} capitalize pl8 mt-2`}>
                          {activity.platform}
                        </p>
                        <p className="small ml-auto text-faded">{dateTimeFormatter(activity.updatedAt, 'date')}</p>
                      </div>
                      <div className="d-flex gap8">
                        {activity.image && (
                          <img className="br4 mt-auto mb-auto" height="28" src={activity.image} alt="" />
                        )}
                        <p className="small pt0 mt-auto mb-auto">{activity.text}</p>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ))}
        </div>
      )}
      {!accountActivityIsLoading && accountActivity && accountActivity.length === 0 && (
        <div className="text-center card mt16">
          <h4 data-testid="no-pitches-available">{t('ACTIVITY-PAGE.NO-ACTIVITY-YET')}</h4>
        </div>
      )}
      {accountActivityIsLoading && (
        <div className="centered-loading">
          <Loading />
        </div>
      )}
    </div>
  );
};

export default ActivityPage;
// function useCallBack(activities: any, any: any) {
//   throw new Error('Function not implemented.');
// }
// function dateTimeFormatter(updatedAt: any, arg1: string) {
//   throw new Error('Function not implemented.');
// }
